//  How to Use Javascript via Webpacker in Rails 6 (and Flatpickr) 
// https://www.youtube.com/watch?v=Hz8d6zPDSrk
import flatpickr from 'flatpickr'
import 'flatpickr/dist/themes/material_red.css'

document.addEventListener('turbolinks:load', () => {

  const flatpickrConfig = {
    altInput: true,
    altFormat: 'M j, Y',
    dateFormat: 'Y-m-d'
  }

  if (!document.querySelector('body.transactions, body.brokerage_transactions')) {
    flatpickr('.flatpickr', flatpickrConfig)
    return;
  }

  const flatpickrDateFrom = flatpickr('#date_from', flatpickrConfig)
  const flatpickrDateTo = flatpickr('#date_to', flatpickrConfig)

  $('input#search_for').focus();
  $('#date_preset').change(function() {
    var date_from, date_to;
    const today = new Date();
    const year = today.getFullYear();
    var month = today.getMonth()
    switch (this.value) {
      case 'YTD':
        date_from = new Date(year, 0, 1)
        date_to = today
        break;
      case 'Last year':
        date_from = new Date(year - 1, 0, 1)
        date_to = new Date(year - 1, 11, 31)
        break;
      case 'Past 12 months':
        var day_of_month = today.getDate();
        if (month == 2 && day_of_month == 29) {
          day_of_month--;
        }
        date_from = new Date(year - 1, month, day_of_month+1)
        date_to = today
        break;
      case 'Last month':
        date_from = new Date(year, month - 1, 1)
        date_to = new Date(year, month, 0)
        break;
      case 'All':
        flatpickrDateFrom.clear()
        flatpickrDateTo.clear()
        return;
    }
    flatpickrDateFrom.setDate(date_from.toISOString().substring(0, 10))
    flatpickrDateTo.setDate(date_to.toISOString().substring(0, 10))
  });

});

